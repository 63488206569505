
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.dictId = ''
      this.dictName = ''
      this.dictType = ''
      this.status = '0'
      this.remark = ''
    } else if (type === 'rule') {
      this.dictName = [
        {
          required: true,
          message: '请输入字典名称',
          trigger: 'blur',
        },
      ]
      this.dictType = [
        {
          required: true,
          message: '请输入字典类型',
          trigger: 'blur',
        },
      ]
      this.status = [
        {
          required: true,
          message: '请选择状态',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
