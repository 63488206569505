
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.dictType = ''
      this.dictLabel = ''
      this.dictValue = ''
      this.dictSort = ''
      this.status = '0'
      this.remark = ''
    } else if (type === 'rule') {
      this.dictLabel = [
        {
          required: true,
          message: '请输入字典标签',
          trigger: 'blur',
        },
      ]
      this.dictValue = [
        {
          required: true,
          message: '请输入字典键值',
          trigger: 'blur',
        },
      ]
      this.dictSort = [
        {
          required: true,
          message: '请输入字典排序',
          trigger: 'blur',
        },
      ]
      this.status = [
        {
          required: true,
          message: '请选择状态',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
