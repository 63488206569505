<template>
  <div class="dialog-container">
    <el-dialog
      title="字典数据"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="70%">
      <div style="margin: 0 10px 10px 0;text-align:right">
        <!-- <el-button class="default-button" @click="openDeleteDialog(selectedArr.map(item => item.id))">{{$t('delete')}}</el-button> -->
        <el-button class="default-button" @click="changeAddDialog(true)">{{$t('add')}}</el-button>
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          @selection-change="handleSelectionChange">
          <el-table-column
            type="index"
            align="center"
            label="序号"
            width="55">
          </el-table-column>
          <el-table-column
            type="selection"
            align="center"
            width="55">
          </el-table-column>
          <el-table-column
            prop="dictCode"
            align="center"
            label="字典编码"
            width="85">
          </el-table-column>
          <el-table-column
            prop="dictLabel"
            align="center"
            label="字典标签"
            width="105">
          </el-table-column>
          <el-table-column
            prop="dictValue"
            align="center"
            min-width="110"
            label="字典键值">
          </el-table-column>
          <el-table-column
            prop="dictSort"
            align="center"
            label="字典排序"
            width="84">
          </el-table-column>
          <el-table-column
            prop="status"
            align="center"
            label="状态"
            width="85">
            <template slot-scope="scope">
              <span>{{ scope.row.status | statusFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            align="center"
            label="备注"
            width="180">
          </el-table-column>
          <el-table-column
            align="center"
            prop="createTime"
            label="创建时间"
            width="175">
          </el-table-column>
          <el-table-column
            align="center"
            width="180px"
            :label="$t('option')">
            <template slot-scope="scope">
              <el-button class="default-button" @click="changeAddDialog(true, true, scope.row)">{{$t('edit')}}</el-button>
              <el-button class="default-button" @click="openDeleteDialog(scope.row.dictCode)">{{$t('delete')}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-wrapper">
      <div class=""></div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total">
        </el-pagination>
      </div>
      <!-- 添加/修改弹窗 -->
      <list-add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></list-add-dialog>
      <!-- 删除确认弹窗 -->
      <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
      <template slot="footer">
        <el-button class="footer-confirm" @click="$emit('update:show', false)">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="$emit('update:show', false)">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import PageClass from '@/unit/pageClass'
import apis from '@/apis'
import confirmDialog from '@/components/confirmDialog.vue'
import listAddDialog from './listAddDialog.vue'

export default {
  components: {
    listAddDialog,
    confirmDialog,
  },
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapState(['Language']),
  },
  data() {
    return {
      tableData: [], // 角色树状的值
      form: new PageClass(),
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
      optionData: '',
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        '1': '停用',
        '0': '正常',
      }
      return statusMap[status]
    },
  },
  methods: {
    handleSizeChange(pageSize) {
      this.form.pageSize = pageSize
      // 业务问题，每次修改分页大小后要不要重新返回第一页
      this.form.pageNum = 1
      this.getDataQuery(this.optionData)
    },
    /**
     * @function 当前页修改方法
     * @param {Number} pageNum 修改后的当前页
     */
    handleCurrentChange(pageNum) {
      this.form.pageNum = pageNum
      this.getDataQuery(this.optionData)
    },
    init() {},
    sureEvent() {
      this.getDataQuery(this.optionData)
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs['addDialog'].getDataQuery(data)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.getDataQuery(this.optionData)
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.optionData = data
      this.$http.get(apis.dictData_list, { params: { ...this.form, dictType: data.dictType } }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http.post(apis.dictData_remove, {}, { params: this.confirmDialog.data }).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.getDataQuery(this.optionData)
        }
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      this.confirmDialog.data.ids = id
      this.changeConfirmDialog(true, 1)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent(this.optionData)
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.less";
@import "../../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-top: 5px;
}
</style>
